@font-face {
  font-family: "GT-America";
  src: local("GT-America"),
    url(../fonts/GT-America/GT-America-Standard-Regular.woff) format("woff");
}

@font-face {
  font-family: "Patron";
  src: local("Patron"), url(../fonts/Patron/Patron-Regular.woff) format("woff");
}

html,
body,
#root {
  margin: 0;
  height: 100%;
  background-image: linear-gradient(180deg, #e4f1f6 0, #f9f9fb 430px);
  font-family: "GT-America", sans-serif;
}

.App {
  height: 100%;
  background-color: #f7f8fa;
  overflow: hidden;
}

.App__header {
  background: #2a264f;
  height: 68px;
  font-family: "GT-America";
  position: relative;
  overflow: hidden;
  padding: 0 30px;
}

.Header__logo-image {
  float: left;
  padding-top: 20px;
}

.Header__profile {
  float: right;
  margin-top: 20px;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  overflow: hidden;
  background-color: rgb(173, 235, 193);
}

.Header__profile-pic {
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
}

.App__header-link-left {
  height: 100%;
  text-transform: uppercase;
  font-size: 11px;
  color: #9090a3;
  float: left;
  text-align: center;
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 28px;
  text-decoration: none;
  letter-spacing: 0.5px;
  margin-left: 2%;
}

.App__header-link-left-selected {
  color: #fff;
  border-top: solid #fff;
  padding-top: 25px;
}

.App__header-link-left:hover {
  color: #fefefe;
  text-decoration: none;
}

.App__content-wrapper {
  margin-top: 3%;
  height: 80%;
  max-width: 820px;
  padding: 32px 32px 40px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(79, 79, 102, 0.1);
  border: 1px solid #dddde6;
  border-radius: 4px;
}

.App__section-header {
  font-size: 24px;
  font-weight: 500;
  color: #3e3d53;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 8px;
  font-family: "GT-America";
}

.App__container {
  height: calc(100% - 68px);
  margin: 0 32px;
}

.App__content {
  height: 100%;
  display: grid;
  grid-template-columns: 25% 75%;
}

.App__content_home {
  height: 100%;
  display: inline-block;
  width: 100%;
}

.App__content-left {
  padding-top: 24px;
}

.App__illustration {
  width: 15%;
  position: fixed;
  bottom: 0%;
  left: 0%;
  margin: 2%;
}

.App__content-right {
  margin-top: 0px;
}

.App__content-right:after {
  content: "";
  width: 100%;
  height: 30px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #f7f8fa;
  z-index: 999;
}

.hidden {
  display: none;
}

.Default {
  height: 100%;
  background-image: linear-gradient(180deg, #e4f1f6 0, #f9f9fb 430px);
  overflow: hidden;
}

.Default__error-wrapper {
  padding: 150px 24px 138px;
  margin: 0 auto;
  position: relative;
  text-align: center;
}

.Default__error-illu {
  position: relative;
  width: 600px;
  max-width: 100%;
}

.Default__error-title {
  font-size: 40px;
  line-height: 52px;
  color: #301c6b;
  font-family: "Patron";
}

.Default__error-subtitle {
  color: #5d21d2;
  font-size: 16px;
  font-weight: 800;
  transition: all 0.1s;
  text-align: center;
  cursor: pointer;
  font-family: Avenir;
  text-decoration: none;
}

.Default__error-subtitle:hover {
  text-decoration: none;
  color: #2a264f;
}

.loader {
  position: absolute;
  font-size: 13px;
  right: 30px;
  max-width: 20%;
  background-color: rgb(173, 235, 193);
  top: 80px;
  left: 0;
  right: 0;

  margin-left: auto;
  margin-right: auto;

  text-align: center;
  padding: 0 1%;
  height: 25px;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(102, 103, 104, 0.2);
  font-family: "GT-America";
  color: green;
}

.icon-loader {
  margin-right: 4px;
}

.loader-text {
  display: inline-block;
  vertical-align: sub;
}

.not_compatible {
  color: 000;
  padding: 15px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.placeholder {
  height: 100%;
  text-align: center;
  background-color: #f7f8fa;
  overflow: auto;
  padding-bottom: 120px;
}

.placeholder-title {
  font-size: 32px;
  font-weight: 500;
  color: #2a264f;
  margin: 48px 24px 24px;
  color: #17114e;
  margin-bottom: 2%;
}

.placeholder-subtitle {
  margin: 0 auto;
  font-size: 16px;
  margin-bottom: 5%;
  max-width: 600px;
  color: #7e7e8f;
  font-weight: 400;
}

.placeholder-separator {
  display: block;
  width: 40%;
  height: 1px;
  border: none;
  opacity: 0.2;
  background-color: #5d21d2;
  margin: 48px auto;
}

.placeholder-control-placeholder {
  width: 75%;
  height: auto;
}

.placeholder-know-more {
  width: 70%;
  max-width: 1080px;
  position: fixed;
  left: 50%;
  bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #27175c;
  margin-top: 48px;
  padding: 16px 24px;
  border-radius: 64px;
  transform: translateX(-50%) translateY(0);
  -webkit-box-shadow: 0px 2px 28px -6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 2px 28px -6px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 2px 28px -6px rgba(0, 0, 0, 0.28);
}

.placeholder-know-more-title {
  font-weight: 500;
  line-height: 1.3;
  color: #fff;
  text-align: left;
  font-size: 16px;
}

.know-more-button {
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  position: relative;
  line-height: 18px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  overflow: hidden;
  z-index: 0;
  background-color: #5d21d2;
  color: #fff;
  text-align: center;
  margin-left: 32px;
}

.know-more-button:hover {
  background-color: #511cba;
}

.know-more-button-inside {
  height: 100%;
  width: 100%;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-radius: 4px;
  box-sizing: border-box;
}

.placeholder-page {
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  min-height: 0;
  overflow: unset;
}

.global-loader {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  pointer-events: none;
  transition: opacity 300ms ease;
  z-index: 1000;
}

.global-loader-gif {
  width: 100px;
}

.calendar-card {
  display: flex;
  background: white;
  z-index: -1;
  width: 650px;
  border-radius: 8px;
  -webkit-box-shadow: 4px 6px 18px -4px rgba(0, 0, 0, 0.31);
  -moz-box-shadow: 4px 6px 18px -4px rgba(0, 0, 0, 0.31);
  box-shadow: 4px 6px 18px -4px rgba(0, 0, 0, 0.15);
}

.calendar {
  margin: 10px auto;
  background: white;
  border-radius: 8px;
  box-shadow: 4px 6px 18px -4px rgba(0, 0, 0, 0.15);
}

.calendar-input {
  padding: 6px;
  border: grey solid 1px;
  border-radius: 4px;
  font-size: 15px;
  /* margin: 0 15px 0 15px; */
}

.DatePicker_explanation_text {
  margin-bottom: 7px;
}

.DatePicker_container {
  height: 60px;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 4%;
}

.App__content_home_iframe {
  height: 90%;
}

.fas {
  margin-left: 10px;
  margin-right: 10px;
}
